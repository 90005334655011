<template>
  <b-modal
    id="modal-manual"
    ref="modal-manual"
    title="Kích hoạt manual"
    size="xl"
    centered
    @hide="resetModal"
  >
    <b-overlay :show="loading">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-row>
          <b-col cols="6">
            <basic-input
              label="Họ và Tên"
              placeholder="Nhập họ và tên"
              name="fullName"
              :value.sync="form.fullName"
              v-validate="'required'"
              :required="true"
              data-vv-as="Họ và Tên"
              :state="validateState('fullName')"
              :invalidFeedback="errors.first('fullName')"
            ></basic-input>
          </b-col>
          <b-col cols="6">
            <basic-input
              label="Số điện thoại"
              placeholder="Nhập số điện thoại"
              name="phoneNumber"
              v-validate="'required'"
              :required="true"
              :value.sync="form.phoneNumber"
              data-vv-as="Số điện thoại"
              :state="validateState('phoneNumber')"
              :invalidFeedback="errors.first('phoneNumber')"
            ></basic-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <basic-input
              label="Mã bệnh nhân"
              placeholder="Nhập mã bệnh nhân"
              name="patientCode"
              :value.sync="form.patientCode"
              data-vv-as="Mã bệnh nhân"
              :state="validateState('patientCode')"
              :invalidFeedback="errors.first('patientCode')"
            ></basic-input>
          </b-col>

          <b-col cols="6">
            <basic-select
              label="Giới tính"
              class="lesson-edit-page__dropdown-filter"
              placeholder="--- Chọn giới tính ---"
              name="sex"
              :required="true"
              v-validate="'required'"
              data-vv-as="Giới tính"
              :state="validateState('sex')"
              :invalidFeedback="errors.first('sex')"
              :options="sexOptions"
              :value.sync="form.sex"
              :solid="false"
              :allowEmpty="false"
              trackBy="value"
              valueLabel="text"
              @update:value="selected($event, 'sex', sexOptions)"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <basic-select
              label="Loại bệnh"
              placeholder="--- Chọn loại bệnh ---"
              name="diseaseType"
              class="lesson-edit-page__dropdown-filter"
              :solid="false"
              :allowEmpty="false"
              :value.sync="form.diseaseType"
              :options="diseaseTypeOptions"
              trackBy="value"
              valueLabel="text"
              v-validate="'required'"
              :required="true"
              data-vv-as="Loại bệnh"
              :state="validateState('diseaseType')"
              :invalidFeedback="errors.first('diseaseType')"
              @update:value="
                selected($event, 'diseaseType', diseaseTypeOptions)
              "
            />
          </b-col>
          <b-col cols="6">
            <basic-input
              label="Số năm"
              placeholder="Nhập số năm"
              name="diseaseYear"
              inputType="number"
              :required="true"
              :value.sync="form.diseaseYear"
              data-vv-as="Số năm bệnh"
              maxlength="4"
              v-validate="{
                required: true,
                max: 4,
              }"
              :state="validateState('diseaseYear')"
              :invalidFeedback="errors.first('diseaseYear')"
            ></basic-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <basic-date-picker
              format="DD/MM/YYYY"
              name="boughtDate"
              label="Ngày mua"
              placeholder="Nhập ngày mua"
              v-model="form.boughtDate"
              v-validate="'required'"
              :required="true"
              data-vv-as="Ngày mua"
              :state="validateState('boughtDate')"
              :invalidFeedback="errors.first('boughtDate')"
              @change="selectDate($event, 'boughtDate')"
            />
          </b-col>
          <b-col cols="6">
            <basic-date-picker
              format="DD/MM/YYYY"
              name="birthDate"
              label="Ngày sinh"
              placeholder="Nhập ngày sinh"
              v-model="form.birthDate"
              v-validate="'required'"
              :required="true"
              data-vv-as="Ngày sinh"
              :state="validateState('birthDate')"
              :invalidFeedback="errors.first('birthDate')"
              @change="selectDate($event, 'birthDate')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <basic-select
              label="Gói dịch vụ"
              placeholder="--- Chọn gói dịch vụ ---"
              name="packageId"
              :solid="false"
              :allowEmpty="false"
              class="lesson-edit-page__dropdown-filter"
              trackBy="value"
              valueLabel="text"
              :value.sync="form.packageId"
              :options="packages"
              v-validate="'required'"
              :required="true"
              data-vv-as="Gói dịch vụ"
              :state="validateState('packageId')"
              :invalidFeedback="errors.first('packageId')"
              @update:value="selected($event, 'packageId', packages)"
            />
          </b-col>
          <b-col cols="6">
            <basic-input
              label="Mã giới thiệu"
              placeholder="Nhập mã giới thiệu"
              name="referralCode"
            ></basic-input>
          </b-col>
        </b-row>
      </form>
    </b-overlay>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          class="btn btn-plain ml-2"
          href="#"
          :disabled="loading"
          @click.stop="handleCancel"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Huỷ
        </b-button>
        <b-button
          class="btn btn-success ml-2"
          href="#"
          :disabled="loading"
          @click.stop="handelValidation"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: 'ModalAddManual',
  data() {
    return {
      loading: false,
      form: {
        fullName: null,
        phoneNumber: null,
        diseaseYear: null,
        sex: null,
        boughtDate: null,
        birthDate: null,
        diseaseType: null,
        packageId: null,
        patientCode: null,
      },
      sexOptions: [
        { text: 'Nam', value: '0' },
        { text: 'Nữ', value: '1' },
      ],
      packages: [],
      diseaseTypeOptions: [
        { text: 'Tiền đái tháo đường', value: 'Tiền đái tháo đường' },
        { text: 'Đái tháo đường típ 1', value: 'Đái tháo đường típ 1' },
        { text: 'Đái tháo đường típ 2', value: 'Đái tháo đường típ 2' },
        { text: 'Đái tháo đường thai kỳ', value: 'Đái tháo đường thai kỳ' },
        { text: 'Tăng Huyết Áp', value: 'Tăng Huyết Áp' },
        { text: 'Béo Phì', value: 'Béo Phì' },
        { text: 'Khác', value: 'Khác' },
      ],
    };
  },
  async mounted() {
    await this.getPackages();
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handelValidation() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.handleSubmit();
      });
    },
    async handleSubmit() {
      var payload = {
        fullName: this.form.fullName,
        phoneNumber: this.form.phoneNumber,
        diseaseYear: this.form.diseaseYear,
        sex: this.form.sex?.value,
        boughtDate: this.convertDateToTimestamp(this.form.boughtDate),
        birthDate: this.convertDateToTimestamp(this.form.birthDate),
        diseaseType: this.form.diseaseType?.value,
        packageId: this.form.packageId?.value,
        patientCode: this.form.patientCode
      };
      try {
        await this.$api.post(
          'PackageAccountTransaction/AddManualPackage',
          payload,
        );
        this.$bvModal.hide('modal-manual');
        this.$emit('loadData');
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Kích hoạt thành công.',
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    resetModal() {
      this.form = {
        fullName: null,
        phoneNumber: null,
        diseaseYear: null,
        sex: null,
        boughtDate: null,
        birthDate: null,
        diseaseType: null,
        packageId: null,
        patientCode: null
      };
    },
    handleCancel() {
      this.resetModal();
      this.$bvModal.hide('modal-manual');
    },
    async getPackages() {
      const { data, error } = await this.$api.get('/Package', {
        params: {
          isRoadmap: true,
        },
      });
      if (error) return;
      this.packages = this.getDropdownOptions(data);
    },
    getDropdownOptions(array) {
      return array.map((item) => {
        return {
          text: item.name,
          value: item.id,
        };
      });
    },
    selected(value, formProperty, optionsArr) {
      this.form[formProperty] = value
        ? optionsArr.find((el) => el.value === value)
        : null;
      if (this.form[formProperty]) {
        this.$validator.reset({ name: formProperty });
      }
    },
    selectDate(value, formProperty) {
      this.form[formProperty] = value ? value : null;
      if (this.form[formProperty]) {
        this.$validator.reset({ name: formProperty });
      }
    },
  },
};
</script>
